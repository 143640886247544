import { createGlobalStyle } from "styled-components";
import { above, below } from "./Breakpoints";

// import bg from "../assets/images/bg.svg";
// import stripes from "../assets/images/stripes.svg";

const GlobalStyles = createGlobalStyle`
  :root {
    --black: #504a6f;
    --blackHover: #47435e;
    --white: #fff;
    --background: #fefcea;
    --primaryPop: #798fe4;
    --primaryPopDarker: #5f79e3;
    --primaryHover: #445dc2;
    --linkHighlight: #fefcea;
    
    --accentDarker: #798fe4;
    --softAccent: #99a9e9;
    --softAltBg: #bac4ed;

    --formBg: #f7f8ff;
    --formgray: #efefef33;
    
    --brightRed: #f20519;
    --midGray: #A1A69C;
    --lightYellow: #f2efc2;
    --salmon: #f2ab91;
    --trueBlack: #0d0d0d;

  }

  html {
    height: -webkit-fill-available;
  }

  body {
    background: var(--background);
    min-height: 100vh;
    min-height: -webkit-fill-available;
  }

  button {
    background: var(--accentDarker);
    color: white;
    border: 0;
    padding: 0.6rem 1rem;
    border-radius: 2px;
    cursor: pointer;
    box-shadow: var(--cast) var(--cast) 0 var(--background);
    text-shadow: 0.5px 0.5px 0 rgba(0,0,0,0.2);
    transition: all 0.2s;
  }

  a {
    text-decoration: none;
    transition: .2s ease all;
    color: var(--black);
    &:hover {
      ${"" /* color: ${({ theme }) => theme.primaryHover} */}
      color: var(--primaryHover);
    }
  }

  /* selects pre-rendered Gatsby image */
  .gatsby-image-wrapper img[src*=base64\\,] {
    image-rendering: -moz-crisp-edges;
    image-rendering: smooth;
  }

  .gatsby-image-wrapper {
    margin-bottom: 1rem;
  }

  /* Scrollbar Styles */
  body::-webkit-scrollbar {
    width: 12px;
  }

  html {
    scrollbar-width: thin;
    scrollbar-color: var(--accentDarker) var(--white);
  }

  body::-webkit-scrollbar-track {
    background: var(--white);
  }

  body::-webkit-scrollbar-thumb {
    background-color: var(--accentDarker) ;
    border-radius: 6px;
    border: 3px solid var(--white);
  }

  hr {
    border: 0;
    height: 8px;
    ${"" /* background-image: url(${stripes}); */}
    background-size: 1500px;
  }

  input[type='submit'] {
    border: none;
    outline: none;
    cursor: pointer;
    transition: .2s ease all;
    &:focus {
      border: 1px solid var(--softAccent);
    }
  }

  img {
    max-width: 100%;
  }

  p {
    margin: 0;
    &::selection {
      color: white;
      background: var(--primaryPop);
    }
    & a {
      color: var(--primaryPop);
      &:hover {
        color: var(--black);
      }
    }
  }

  figure {
    margin-inline-start: 0;
    margin-inline-end: 0;
  }

  .tilt {
    transform: rotate(-2deg);
    position: relative;
    display: inline-block;
  }

  .flow {
    & * + * {
      margin-bottom: 2rem;
    }
  }

`;

export default GlobalStyles;
