import React, { useState, useEffect, useRef } from "react";
import Footer from "./Footer";
import Nav from "./Nav";
import Burger from "./Burger";
import { useOnClickOutside } from "../utils/useOnClickOutside";
import styled from "styled-components";
import { ThemeProvider } from "styled-components";
import { theme } from "../styles/Theme";
import { Link } from "gatsby";
import GlobalStyles from "../styles/GlobalStyles";
import RemedyStyles from "../styles/RemedyStyles";
import Typography from "../styles/Typography";
import PostStyles from "../styles/PostStyles";
import RJMIcon from "../../static/RJM_logo.svg";
import { below } from "../styles/Breakpoints";

const LayoutStyles = styled.div`
  padding-bottom: 2rem;
  min-height: 100vh;

  & .rjm-logo {
    position: fixed;
    top: 1rem;
    right: 1rem;
    width: 130px;
    z-index: 2;
    & .cls-2 {
      opacity: 0;
      transition: 0.2s ease-in all;
    }
    &:hover .cls-2 {
      opacity: 1;
    }
    ${below.laptopL`
      position: absolute;
    `}
    ${below.tablet`
      display: none;
    `}
  }
`;

export default function Layout({ children }) {
  const [loaded, setLoaded] = useState(false);
  const [pageChange, setPageChange] = useState(false);

  useEffect(() => {
    setLoaded(true);
  }, []);

  const childrenWithLoadedProp = React.Children.map(children, child =>
    React.cloneElement(child, { loaded })
  );

  // Nav burger click functionality
  const node = useRef();
  const [open, setOpen] = useState(false);
  useOnClickOutside(node, () => {
    setOpen(false);
    setPageChange(false);
  });

  return (
    <ThemeProvider theme={theme}>
      <RemedyStyles />
      <GlobalStyles />
      <Typography />
      <PostStyles />
      <LayoutStyles>
        <Link to="/" aria-label="Go to home">
          <RJMIcon className="rjm-logo" />
        </Link>
        <div ref={node} style={{ height: "100%" }}>
          <Burger
            open={open}
            setOpen={setOpen}
            pageChange={pageChange}
            setPageChange={setPageChange}
          />
          <Nav
            open={open}
            setOpen={setOpen}
            pageChange={pageChange}
            setPageChange={setPageChange}
          />
        </div>
        {childrenWithLoadedProp}
        <Footer />
      </LayoutStyles>
    </ThemeProvider>
  );
}
