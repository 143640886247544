import { createGlobalStyle } from "styled-components";

const PostStyles = createGlobalStyle`

  .post-meta-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 0.85rem;
    font-weight: 500;
    margin-bottom: .75rem;
    font-style: italic;
    margin-top: 1.5rem;
    & a {
      font-weight: 600;
    }
  }

  .post-divider {
    width: 100%;
    height: 2px;
    background: var(--primaryPop);
  }

  .post-body {
    & p {
      margin-bottom: 1rem;
    }
    & figure {
      margin: 1.5rem auto;
      & img {
        margin: 0 auto;
        width: 100%;
      }
    }
  }

`;

export default PostStyles;
