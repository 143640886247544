import React from "react";
import styled from "styled-components";

export const StyledFooter = styled.footer`
  text-align: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-size: .85rem;
  background: var(--primaryPop);
  width: 100%;
  color: white;
`;

export default function Footer() {
  return (
    <StyledFooter>
      <p style={{marginTop: "1rem"}}>&copy; Reclaim Justice Movement {new Date().getFullYear()}</p>
    </StyledFooter>
  );
}
