// Menu.js
import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { bool } from "prop-types";
import { StyledNav } from "./Nav.styled";

const Nav = ({ open, setOpen, pageChange, setPageChange, path, ...props }) => {
  const [projectsOpen, setProjectsOpen] = useState(false);
  const subPaths = [
    "/military-sexual-harm/",
    "/workshops/",
    "/abolition-imagination-sessions",
  ];
  const highlightSub = subPaths.includes(path);

  const pathName =
    typeof window !== "undefined"
      ? location.href.substring(location.href.lastIndexOf("/") + 1)
      : "";

  useEffect(() => {
    if (highlightSub) {
      setProjectsOpen(true);
    }
  }, []);

  function handleLinkClick() {
    setPageChange(true);
    setOpen(false);
  }

  function handleProjectsClick() {
    setProjectsOpen(prevProjectsOpen => setProjectsOpen(!prevProjectsOpen));
  }

  return (
    <StyledNav
      open={open}
      pageChange={pageChange}
      projectsOpen={projectsOpen}
      highlightSub={highlightSub}
    >
      <ul>
        <li>
          <Link
            to="/"
            activeStyle={{ display: "none" }}
            onClick={handleLinkClick}
          >
            Home
          </Link>
        </li>
        <li>
          <Link to="/about" activeClassName="active" onClick={handleLinkClick}>
            About
          </Link>
        </li>
        <li>
          <Link
            to="/#getInTouch"
            activeClassName="active"
            onClick={handleLinkClick}
            disabled={pathName === "#getInTouch"}
          >
            Contact
          </Link>
        </li>
        {/* <li>
          <Link to="/news" activeClassName="active">
            News
          </Link>
        </li> */}
        <li>
          <Link to="https://www.patreon.com/reclaimjustice?fan_landing=true">
            Donate
          </Link>
        </li>
        <li>
          <button
            className="projects-button"
            onClick={handleProjectsClick}
            disabled={highlightSub}
          >
            Projects
          </button>
          <div className="projects-container">
            <Link
              to="/abolition-imagination-sessions"
              activeClassName="active"
              onClick={handleLinkClick}
            >
              Abolition Imagination Sessions
            </Link>
            <Link
              to="/military-sexual-harm"
              activeClassName="active"
              onClick={handleLinkClick}
            >
              Military Sexual Harm
            </Link>
            <Link
              to="/workshops"
              activeClassName="active"
              onClick={handleLinkClick}
            >
              Workshops
            </Link>
          </div>
        </li>
        <li>
          <Link to="/team" activeClassName="active" onClick={handleLinkClick}>
            Team
          </Link>
        </li>
      </ul>
    </StyledNav>
  );
};

Nav.propTypes = {
  open: bool.isRequired,
};

export default Nav;
