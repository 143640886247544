import styled from "styled-components";
import { above, below } from "../../styles/Breakpoints";

export const StyledNav = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: var(--softAltBg);
  height: 100vh;
  text-align: left;
  padding: 2rem;
  padding-right: 1.5rem;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  -moz-transition: ${({ open, pageChange }) =>
    open || !pageChange ? "transform 0.3s ease-in-out" : "unset"};
  transition: ${({ open, pageChange }) =>
    open || !pageChange ? "transform 0.3s ease-in-out" : "unset"};
  transform: ${({ open }) => (open ? "translateX(0)" : "translateX(-100%)")};
  ${below.mobileL`
    width: 100%
  `}

  ul {
    padding-left: 0;
    left: -0.6rem;
    top: 6.2rem;
    position: relative;
  }

  & .active {
    color: var(--linkHighlight);
    pointer-events: none;
  }

  a {
    font-size: 2rem;
    text-transform: uppercase;
    font-weight: bold;
    color: ${({ theme }) => theme.primaryDark};
    text-decoration: none;
    transition: color 0.2s ease;
    ${below.mobileL`
    text-align: center;
    `}

    &:hover {
      color: ${({ theme }) => theme.primaryLight};
    }
  }

  & button {
    background: transparent !important;
    font-size: 2rem;
    margin-top: 2px;
    text-transform: uppercase;
    font-weight: bold;
    color: ${({ projectsOpen, highlightSub }) =>
      projectsOpen && highlightSub ? "var(--background)" : "var(--black)"};
    letter-spacing: 0.5px;
    padding: 0;
    line-height: 1.5;
    transition: 0.2s ease color;
    &:hover {
      color: white;
    }
    &[disabled] {
      pointer-events: none;
    }
  }

  .projects-container {
    display: flex;
    visibility: ${({ projectsOpen }) => (projectsOpen ? "visible" : "hidden")};
    opacity: ${({ projectsOpen }) => (projectsOpen ? "1" : "0")};
    height: ${({ projectsOpen }) => (projectsOpen ? "100%" : "0")};
    flex-direction: column;
    margin-top: ${({ projectsOpen }) => (projectsOpen ? ".35rem" : "0")};
    & a {
      font-size: 0.9rem;
      margin-bottom: 0.5rem;
      margin-right: auto;
    }
  }
`;
