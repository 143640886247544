// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-abolition-imagination-sessions-js": () => import("./../../../src/pages/abolition-imagination-sessions.js" /* webpackChunkName: "component---src-pages-abolition-imagination-sessions-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-military-sexual-harm-js": () => import("./../../../src/pages/military-sexual-harm.js" /* webpackChunkName: "component---src-pages-military-sexual-harm-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-workshops-js": () => import("./../../../src/pages/workshops.js" /* webpackChunkName: "component---src-pages-workshops-js" */),
  "component---src-templates-news-list-template-js": () => import("./../../../src/templates/news-list-template.js" /* webpackChunkName: "component---src-templates-news-list-template-js" */),
  "component---src-templates-news-post-template-js": () => import("./../../../src/templates/news-post-template.js" /* webpackChunkName: "component---src-templates-news-post-template-js" */)
}

