// Burger.js
import React from "react";
import { bool, func } from "prop-types";
import { StyledBurger } from "./Burger.styled";
const Burger = ({ open, setOpen, pageChange, setPageChange }) => {
  return (
    <StyledBurger
      open={open}
      onClick={() => {
        setOpen(!open);
        setPageChange(false);
      }}
      aria-label="Open nav menu"
    >
      <div />
      <div />
      <div />
    </StyledBurger>
  );
};
Burger.propTypes = {
  open: bool.isRequired,
  setOpen: func.isRequired,
};
export default Burger;
