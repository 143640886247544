import { createGlobalStyle } from "styled-components";

const Typography = createGlobalStyle`
  
  html {
    font-family: 'Open Sans', 'Helvetica Neue', sans-serif; 
    font-display: swap;
    color: var(--black);
  }

  p {
    margin-bottom: 1rem;
  }

  p, li {
    letter-spacing: 0.5px;
    line-height: 1.5;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: bold;
    font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
    font-display: swap;
  }

  h1, h2 {
    margin-bottom: 1.5rem;
    margin-top:0;
  }

  h1 {
    font-size: 3rem;
  }

  h2 {
    font-size: 2.5rem;
  }

  .header-underline {
    width: 100%;
    height: 2px;
    background: var(--primaryPop);
    position: relative;
    top: -1rem;
    margin-bottom: 1rem;
  }

  a {
    color: var(--black);
  }

  mark, .mark {
    background: var(--softAccent);
    padding: 0 2px 2px 2px;
    margin: 0;
    display: inline;
    line-height: 1;
  }

  ul {
    list-style-type: none; 
    & a {
    text-decoration: none;
    &:hover {
      color: var(--white);
      }
    }
  }

  .bold {
    font-weight: 700;
  }

  .italic {
    font-style: italic;
  }

  .center {
    text-align: center;
  }

  .tilt {
    transform: rotate(-2deg);
  }

  .headerLink {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.5rem;
    margin-top: 1.5rem;
    display: inline-block;
    border: 2px solid var(--black);
    padding: .5rem 1rem;
    border-radius: 20px;
    transition: background .5s all;
    &:hover {
      background: white;
    }
  }
  
`;

export default Typography;
